<template>
  <div class="popup_wrap" style="width:600px; height:550px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P100.001') }}</h1>
      <div class="content_box mt10">
        <table class="tbl_col">
          <colgroup>
            <col width="33%">
            <col width="33%">
            <col width="34%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('cp.CTRL020P100.002') }}</th>
              <th>{{ $t('cp.CTRL020P100.003') }}</th>
              <th>{{ $t('cp.CTRL020P100.004') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select v-model="param.rteCd" @change="rteCdChg()">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in fairwayList" :key="idx" :value="item.rteCd">{{ item.rteCd }}</option>
                </select>
              </td>
              <td>
                <select v-model="param.vslCd" @change="vslCdChg()">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in shipNmList" :key="idx" :value="item.vslCd">{{ item.vslCd }}</option>
                </select>
              </td>
              <td>
                <input type="text" v-model="param.voyageNo" @keydown="preventComSl($event)">
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text_center mt10">
          <button class="tbl_icon arrowdown" @click.prevent="add">arrowdown</button>
          <button class="tbl_icon arrowup ml20" @click.prevent="del">arrowup</button>
        </div>
        <div id="realgrid" class="mt10" style="width: 100%; height: 250px" />
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="select">{{ $t('cp.COMMON.002') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import scenarioAction from '@/api/rest/cp/scenarioAction'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'rteCd', dataType: 'text' },
  { fieldName: 'rteNm', dataType: 'text' },
  { fieldName: 'vslCd', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyageNo', dataType: 'text' }
]
const columns = [
  { name: 'rteCd', fieldName: 'rteCd', header: { text: app.$t('cp.CTRL020P100.002') }, editable: false, width: 30 },
  { name: 'rteNm', fieldName: 'rteNm', header: { text: app.$t('cp.CTRL020P100.005') }, visible: false },
  { name: 'vslCd', fieldName: 'vslCd', header: { text: app.$t('cp.CTRL020P100.003') }, editable: false, width: 30 },
  { name: 'vslNm', fieldName: 'vslNm', header: { text: app.$t('cp.CTRL020P100.006') }, visible: false },
  { name: 'voyageNo', fieldName: 'voyageNo', header: { text: app.$t('cp.CTRL020P100.004') }, editable: false, width: 30 }
]

export default {
  name: 'RtVesVoyagePop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          pRtVesVoyage: ''
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        rteCd: '',
        rteNm: '',
        vslCd: '',
        vslNm: '',
        voyageNo: ''
      },
      selectList: {
        vslCd: '',
        dpoVoyNo: '',
        rteCd: '',
        porPlc: '',
        dlyPlc: '',
        porCtr: '',
        dlyCtr: '',
        rvsdDpoDt: ''
      },
      fairwayList: [],
      shipNmList: []
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  async mounted () {
    console.log('mounted')
    const $vm = this

    await $vm.getFairwayList() //항로
    await $vm.getShipNmList() //선명

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)
    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    if ($vm.parentInfo.pRtVesVoyage !== '' && $vm.parentInfo.pRtVesVoyage.length > 0) {
      const rtVesVoyages = $vm.parentInfo.pRtVesVoyage.split(',').map((ele) => ele.split('/'))

      for (const rtVesVoyage of rtVesVoyages) {
        const param = {
          rteCd: rtVesVoyage[0],
          rteNm: '',
          vslCd: rtVesVoyage[1],
          vslNm: '',
          voyageNo: rtVesVoyage[2]
        }

        // 항로 체크
        for (const fairway of $vm.fairwayList) {
          if (param.rteCd === fairway.rteCd) {
            param.rteNm = fairway.rteNm
            break
          }
        }

        // 선명 체크
        for (const shipNm of $vm.shipNmList) {
          if (param.vslCd === shipNm.vslCd) {
            param.vslNm = shipNm.vslNm
            break
          }
        }

        provider.addRow(param)
      }
    }

    gridView.setCheckBar({
      visible: true,
      syncHeadCheck: true
    })
  },
  methods: {
    rteCdChg: function () {
      if (this.param.rteCd !== '') {
        const filterRes = this.fairwayList.filter((row) => row.rteCd === this.param.rteCd)
        if (filterRes.length === 1) {
          this.param.rteNm = filterRes[0].rteNm
        }
      } else {
        this.param.rteNm = ''
      }
    },
    vslCdChg: function () {
      if (this.param.vslCd !== '') {
        const filterRes = this.shipNmList.filter((row) => row.vslCd === this.param.vslCd)
        if (filterRes.length === 1) {
          this.param.vslNm = filterRes[0].vslNm
        }
      } else {
        this.param.vslNm = ''
      }
    },
    async getFairwayList () {
      await cpCommonInfo.fairwayList().then(response => {
        this.fairwayList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    async getShipNmList () {
      await cpCommonInfo.shipNmList().then(response => {
        this.shipNmList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    async select () {
      let rvsdDpoDt = ''
      let closeDt = ''
      let reqRno = ''
      let existItems = ''
      let rate10Down = false

      // 메인화면 사이드메뉴 프로모션 설정시 조건 P101
      if (this.parentInfo.actionCd === 'P101') {
        this.selectList.vslCd = gridView.getValue(0, 'vslCd') // 프로모션 등록시 1건만 등록하여 사용하므로 itemindex 0으로 고정
        this.selectList.dpoVoyNo = gridView.getValue(0, 'voyageNo')
        this.selectList.rteCd = gridView.getValue(0, 'rteCd')
        this.selectList.porPlc = this.parentInfo.porPlc
        this.selectList.dlyPlc = this.parentInfo.dlyPlc
        this.selectList.porCtr = this.parentInfo.porCtr
        this.selectList.dlyCtr = this.parentInfo.dlyCtr

        // 출항일자 조회로 항로/선명/항차 검증
        await scenarioAction.selectRvsdDpoDt(this.selectList).then(response => {
          rvsdDpoDt = response.data
        }).catch(err => {
          console.log(err)
        })

        if (rvsdDpoDt.length === 0) {
          this.openAlert(this.$t('cp.CTRL020P100.007'))
          return
        }

        this.selectList.rvsdDpoDt = rvsdDpoDt[0]

        // 표준운임 존재 여부 체크
        await scenarioAction.selectPopFreRateNo(this.selectList).then(response => {
          reqRno = response.data
        }).catch(err => {
          console.log(err)
        })

        if (reqRno.length === 0) {
          this.openAlert(this.$t('cp.CTRL020P100.008'))
          return
        } else if (reqRno.usdAmt < 10) {
          this.openAlert(this.$t('cp.CTRL020P100.009'))
          rate10Down = true
        }

        // 마감일자 체크
        await scenarioAction.selectCloseDt(this.selectList).then(response => {
          closeDt = response.data
        }).catch(err => {
          console.log(err)
        })

        if (closeDt.length === 0) {
          this.openAlert(this.$t('cp.CTRL020P100.010'))
          return
        }

        if (!rate10Down) {
          existItems = provider.getJsonRows().map((row) => `${row.rteCd}/${row.vslCd}/${row.voyageNo}`) + '/' + rvsdDpoDt
        } else {
          existItems = provider.getJsonRows().map((row) => `${row.rteCd}/${row.vslCd}/${row.voyageNo}`) + '/' + rvsdDpoDt + '/' + rate10Down
        }
      } else {
        existItems = provider.getJsonRows().map((row) => `${row.rteCd}/${row.vslCd}/${row.voyageNo}`)
      }

      this.$emit('close', existItems)
      this.layerClose()
    },
    add: function () {
      this.param.voyageNo = this.param.voyageNo.toUpperCase()
      const selectedRow = this.param
      const length = Object.values(selectedRow).length
      if (Object.values(selectedRow).filter((value) => value === '').length !== length) { // 추가할 항목이 전부 빈 데이터가 아닌 경우
        const existRows = provider.getJsonRows()
        let isExist = false
        for (const existRow of existRows) {
          const e = existRow
          const s = selectedRow

          if (Object.values(e).length === Object.values(s).length && e.rteCd === s.rteCd && e.rteNm === s.rteNm && e.vslCd === s.vslCd && e.vslNm === s.vslNm && e.voyageNo === s.voyageNo) {
            this.openAlert(this.$t('cp.CTRL020P100.011'))
            isExist = true
            break
          }

          if (this.parentInfo.actionCd === 'P101' && selectedRow.voyageNo === '') {
            this.openAlert(this.$t('cp.CTRL020P100.012'))
            isExist = true
            break
          }

          if (this.parentInfo.actionCd === 'P101' && existRow.voyageNo !== '') {
            this.openAlert(this.$t('cp.CTRL020P100.013'))
            isExist = true
            break
          }
        }
        if (!isExist) {
          provider.addRow(this.param)
        }
      } else {
        this.openAlert(this.$t('cp.CTRL020P100.014'))
      }
    },
    del: function () {
      provider.removeRows(gridView.getCheckedRows())
      gridView.checkAll(false)
    },
    preventComSl: function (e) {
      const preventList = [',', '/']
      if (preventList.includes(e.key)) {
        e.preventDefault()
        this.openAlert(this.$t('cp.CTRL020P100.015'))
      }
    }
  }
}
</script>
